<template>
    <v-dialog v-model="dialog" persistent max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" :disabled="disabled" block> Pay </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <v-card-text>{{ description }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false"> Cancel </v-btn>
          <v-btn color="primary" outlined text @click="action"> Continue </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    title: String,
    description: String,
    pay: Function,
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    async action() {
      this.dialog = false
      this.pay();
    }
  },
}
</script>
