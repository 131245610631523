<template>
  <div class="flex justify-content-center">
    <div class="completed" v-if="completed">
      <h3>Payment successfully completed.</h3>
      <v-icon size="80" color="primary">
        {{ icons.mdiCheckCircleOutline }}
      </v-icon>
      <div class="line-row">
        <v-btn to="billing?tab=subscription">My Subscriptions</v-btn>
        <v-btn to="billing?tab=history">Payment History</v-btn>
      </div>
    </div>

    <div class="wait" v-if="loading && !completed">
      <h3>Please wait a few seconds...</h3>
      <v-progress-circular indeterminate color="primary" size="60"></v-progress-circular>
    </div>

    <v-row v-else-if="!loading && !completed">
      <v-col cols="12" md="8">
        <div class="payment-content">
          <v-card>
            <v-card-text class="payment-methods">
              <div class="pay-methods-header">
                <h2>Payment methods</h2>
                <v-btn text outlined @click="newPayment = true">
                  <v-icon style="margin-right: 5px">mdi-credit-card</v-icon>
                  Add card
                </v-btn>
              </div>

              <v-row v-if="loading_payments">
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-card outlined>
                    <v-card-text>
                      <v-skeleton-loader type="sentences, sentences"></v-skeleton-loader>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-card outlined>
                    <v-card-text>
                      <v-skeleton-loader type="sentences, sentences"></v-skeleton-loader>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-card outlined>
                    <v-card-text>
                      <v-skeleton-loader type="sentences, sentences"></v-skeleton-loader>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-item-group v-model="card_index">
                <v-row>
                  <v-col v-for="card in paymentCards" :key="card.id" cols="12" sm="6" md="4" lg="3">
                    <v-item v-slot="{ active, toggle }">
                      <v-card
                        :style="active ? 'border: 2px solid #2ECC71' : ''"
                        outlined
                        @click="
                          () => {
                            toggle()
                            card_selected = card
                          }
                        "
                      >
                        <v-card-text class="">
                          <div class="d-flex justify-space-between">
                            <div>
                              {{ card.cardInfo }}
                            </div>
                            <div>
                              <v-img
                                contain
                                max-width="3rem"
                                height="25"
                                :src="require(`@/assets/images/logos/${card.cardType}.png`)"
                              ></v-img>
                            </div>
                          </div>
                          <div>{{ card.cardOwnerFirstName }} {{ card.cardOwnerLastName }}</div>
                        </v-card-text>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-text class="payment-plans">
              <h2>Subscriptions</h2>

              <div class="plans" v-if="loading_services">
                <v-card outlined class="card-plan">
                  <v-card-text>
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                  </v-card-text>
                </v-card>
                <v-card outlined class="card-plan">
                  <v-card-text>
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                  </v-card-text>
                </v-card>
                <v-card outlined class="card-plan">
                  <v-card-text>
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                  </v-card-text>
                </v-card>
              </div>

              <div class="plans">
                <v-card
                  v-for="service in subscriptions"
                  :key="service.id"
                  class="card-plan"
                  :style="service.id === plan_selected.id ? 'border: 2px solid #2ECC71 !important;' : ''"
                  outlined
                  @click="() => selectPlan(service)"
                >
                  <v-card-title>
                    <span class="card-plan-title">{{ service.name }}</span>
                  </v-card-title>
                  <v-card-text>
                    <p class="text-h4 text--primary">${{ service.price }}</p>
                    <p>{{ service.description }}</p>
                  </v-card-text>
                </v-card>
              </div>

              <h2>Pay as you go</h2>

              <div class="plans">
                <v-card
                  v-for="service in singlePlans"
                  :key="service.id"
                  class="card-plan"
                  :style="service.id === plan_selected.id ? 'border: 2px solid #2ECC71 !important;' : ''"
                  outlined
                  @click="() => selectPlan(service)"
                >
                  <v-card-title>
                    <span class="card-plan-title">{{ service.name }}</span>
                  </v-card-title>
                  <v-card-text>
                    <p class="text-h4 text--primary">${{ service.price }}</p>
                    <p>{{ service.description }}</p>
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>

      <v-col cols="12" md="4">
        <v-card>
          <v-card-text>
            <h2 class="resume-title">Resume</h2>

            <div class="resume">
              <span class="bold">Plan</span>
              <div class="resume-item">
                <span>{{ plan_selected ? plan_selected.name : null }}</span>
                <span>{{ plan_selected.price ? `$ ${plan_selected.price}` : null }}</span>
              </div>

              <v-divider></v-divider>

              <div class="resume-item">
                <h3>Total</h3>
                <span class="bold">{{ plan_selected.price ? `$ ${plan_selected.price}` : null }}</span>
              </div>
            </div>

            <div class="resume-method">
              <span class="bold">Payment Method</span>
              <p v-if="card_selected">
                {{ card_selected.cardInfo }}
              </p>
            </div>

            <v-alert v-if="errors && errors.length > 0" color="error" text>
              <p class="font-weight-semibold mb-1">Messages</p>
              <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
                {{ error }}
              </p>
            </v-alert>

            <dialog-confirm
              :disabled="!(plan_selected.id && card_selected.paymentID)"
              title="Confirm Your Purchase"
              :description="'Do you want to buy ' + plan_selected.name + ' for $' + plan_selected.price"
              :pay="pay"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <new-payment-profile :is-plan-upgrade-open.sync="newPayment" :refresh="getPayments" />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import NewPaymentProfile from '@/views/pages/billing/components/Billing/NewPaymentProfile.vue'
import DialogConfirm from './components/DialogConfirm.vue'
import { mdiCheckCircleOutline } from '@mdi/js'

export default {
  components: {
    NewPaymentProfile,
    DialogConfirm,
  },
  data() {
    return {
      newPayment: false,
      loading: false,
      completed: false,
      loading_payments: false,
      loading_services: false,
      subscriptions: null,
      singlePlans: null,
      paymentCards: null,
      plan_index: null,
      errors: null,
      icons: {
        mdiCheckCircleOutline,
      },
      plan_selected: {
        id: '',
        name: '',
        price: '',
      },
      card_index: null,
      card_selected: {
        paymentID: '',
      },
      transaction_type: 'payment_profile',
    }
  },
  mounted() {
    this.getServices()
    this.getPayments()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/set',
      services: 'payment/getServices',
      payments: 'payment/getPayments',
      paymentProcess: 'payment/paymentProcess',
    }),
    async getPayments() {
      this.loading_payments = true
      await this.payments()
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.profilesData) {
              this.paymentCards = resp.data.profilesData.profiles
            }
          }
        })
        .finally(() => {
          this.loading_payments = false
        })
    },
    async getServices() {
      this.loading_services = true
      await this.services()
        .then(resp => {
          if (resp && resp.data) {
            this.subscriptions = resp.data.services
            this.singlePlans = resp.data.singlePayment

            if (resp.data.services) {
              resp.data.services.filter((row, index) => {
                if (row.id === Number(this.$route.query.subscription)) {
                  this.plan_selected = row
                  this.plan_index = index
                }
              })
            }

            if (resp.data.singlePayment) {
              resp.data.singlePayment.filter((row, index) => {
                if (row.id === Number(this.$route.query.product)) {
                  this.plan_selected = row
                  this.plan_index = index
                }
              })
            }
          }
        })
        .finally(() => {
          this.loading_services = false
        })
    },
    async pay() {
      this.loading = true

      let data = {
        transaction_type: this.transaction_type,
        payment_profile_id: this.card_selected.paymentID,
        service_id: this.plan_selected.id,
        card_type: this.card_selected.cardType,
      }

      await this.paymentProcess(data)
        .then(resp => {
          if (resp && resp.data) {
            this.setSnackbar({
              status: true,
              text: 'Payment successfully completed.',
              color: 'primary',
            })

            this.clean()
            this.completed = true
          }
        })
        .catch(error => {
          this.setSnackbar({
            status: true,
            text: 'Transaction could not be completed.',
            color: 'error',
          })

          if (error.response.data && error.response.data.status === 'error') {
            this.errors = error.response.data.errors
          } else {
            this.errors = null
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectPlan(plan) {
      this.plan_selected = plan
    },
    clean() {
      this.transaction_type = null
      this.card_selected = null
      this.plan_selected = null
      this.errors = null
    },
  },
}
</script>

<style lang="css" scoped>
.active {
  border: 2px solid #2ecc71;
}
.wait {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.completed {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.line-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.payment-content {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.payment-methods {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.payment-plans {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.pay-methods-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bold {
  font-weight: 600;
}
.resume {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.resume-title {
  margin-bottom: 20px;
}
.resume-item {
  min-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.resume-method {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  gap: 10px;
}
.plans {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}
.card-plan {
  width: 245px;
  min-height: 180px;
}
.card-plan-title {
  font-size: 16px;
}
@media (max-width: 610px) {
  .card-plan {
    width: 100%;
    min-height: auto;
  }
}
</style>
